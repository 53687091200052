import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from 'react-grid-gallery';

const IMAGES =
[{
        src: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/boot_gif.gif",
        thumbnail: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/boot_gif.gif",
        thumbnailWidth: 350,
        thumbnailHeight: 254,
        caption: "Boot Screen loaded from .bmp image"
},
{
        src: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/base_gui_gif.gif",
        thumbnail: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/base_gui_gif.gif",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Swapping between different windows"
},

{
        src: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/mouse_gif.gif",
        thumbnail: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/mouse_gif.gif",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Mouse functionality"
},

{
        src: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/taskbar_gif.gif",
        thumbnail: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/taskbar_gif.gif",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Taskbar to open user programs"
},

{
        src: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/general_use.gif",
        thumbnail: "https://raw.githubusercontent.com/matthew-pham/matthew-pham.github.io/master/images/general_use.gif",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        caption: "Ability to run multiple programs simultaneously"
}]
const Windows9 = () => (
    <div>
    <Layout>
      <SEO title="Windows 9" />
      <h1>Windows 9</h1>     
      <h4><i>A Linux-inspired Operating System with a Windows-inspired GUI</i></h4>
      <p>For half of the Spring 2020 semester, I worked in a group of 4 to develop an operating system from scratch for ECE 391 - Computer Systems Engineering. Along with the assignment's 
            requirements we decided to work on various extra features that I am very proud of. Specifically, I worked on initializing the Interrupt Descriptor Table, setting up System Calls, writing the File System driver,
            developing the Graphical User Interface, writing the mouse driver, and helping with other various features. We call our final product Windows 9.
      </p>
      <Gallery images={IMAGES}></Gallery><br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br> <br></br>      
      <h2>Main Features</h2>
      <ul>
        <li>Graphical User Interface using VGA ModeX</li>
        <li>Mouse and Keyboard Input</li>
        <li>Dynamic Memory Allocation - Allocator based on Linux's Buddy Allocator</li>
        <li>Lazy Allocation for User Program Memory</li>
        <li>Ability to Schedule Kernel code (kthreads)</li>
        <li>File System based on Linux's EXT2</li>
        <li>Ability to draw .bmp images from File System</li>
        <li>Virtualized Real Time Clock</li>
      </ul>
    </Layout>
    </div>
   )
 
export default Windows9
